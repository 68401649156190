import React from 'react';
import { SearchStoryblok } from './types';
import { SearchBar } from '../../../core/SearchBar';

interface Props {
  blok: SearchStoryblok;
}

export const Search = ({ blok }: Props) => {
  const { placeholder, hide_gender_picker, disable_responsive_styles, custom_collection, custom_collection_name } = blok || {};

  return <SearchBar placeholder={placeholder} hide_gender_picker={hide_gender_picker} disable_responsive_styles={disable_responsive_styles} forceToSearch={true} custom_collection={custom_collection} custom_collection_name={custom_collection_name} />
};

export default Search;
